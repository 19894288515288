import { ReactComponent as LAANCIcon } from 'assets/icons/laancIcon.svg';
import { ReactComponent as PilotInstituteIcon } from 'assets/icons/pilotInstitute.svg';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
	contactUsLink,
	laancLink,
	freeCoursesLink,
	aboutAloftLink,
	aboutPilotInstituteLink,
	termsLink,
	aboutB4UFLYLink,
	safetyGuidelinesLink
} from 'constants/externalLinks';
import { $primary } from 'styles/colors';

export const leftNavItems = [
	{
		icon: <LAANCIcon />,
		name: 'Get LAANC',
		link: laancLink
	},
	{ icon: <PilotInstituteIcon />, name: 'Free Courses', link: freeCoursesLink },
	{
		icon: <EmailOutlinedIcon fontSize='large' sx={{ color: $primary }} />,
		name: 'Contact Us',
		link: contactUsLink
	}
];

export const leftNavLinks = [
	{ name: 'About Pilot Institute', link: aboutPilotInstituteLink },
	{ name: 'About Aloft', link: aboutAloftLink },
	{ name: 'About B4UFLY', link: aboutB4UFLYLink },
	{ name: 'Safety Guidelines', link: safetyGuidelinesLink },
	{ name: 'Terms of Use', link: termsLink }
];
