// import { List, ListItem, ListItemIcon, Divider, Zoom, Paper, Collapse, ListItemButton, ListItemText } from '@mui/material';
// import { leftNavItems } from 'constants/navigationConstants';
// import { $backgroundOffWhite, $primary } from 'styles/colors';
// import StyledTooltip from 'components/navigation/StyledTooltip';
// import AppIconImg from 'assets/images/appLogo.png';
// import { handleMenuItemClickEvent } from 'actions/firebase';
// import queryString from 'query-string';
// import AloftIconImg from 'assets/images/aloft.png';
// import {useState} from 'react';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//
// const styles = {
// 	main: {
// 		width: '75px',
// 		zIndex: 200,
// 		display: 'flex',
// 		flexDirection: 'column',
// 		position: 'fixed',
// 		left: '20px',
// 		top: '20px',
// 		backgroundColor: $backgroundOffWhite,
// 		height: 'fit-content',
// 		padding: '0px 10px',
// 		borderRadius: '35px'
// 	},
// 	logoContainer: {
// 		height: '100px',
// 		display: 'flex',
// 		paddingBottom: '20px'
// 	},
// 	list: {
// 		paddingTop: '20px',
// 		paddingBottom: '20px'
// 	},
// 	listItem: {
// 		paddingTop: '10px',
// 		paddingBottom: '10px',
// 		display: 'flex',
// 		justifyContent: 'center',
// 		height: '61px'
// 	},
// 	listItemIcon: {
// 		display: 'flex',
// 		justifyContent: 'center'
// 	}
// };
//
// const SideMenu = () => {
// 	const handleClickListItem = item => {
// 		handleMenuItemClickEvent(item.name);
// 		let link = item.link;
// 		if (item.name === 'Submit Data') {
// 			const queryParams = queryString.parse(window.location.search);
// 			link = `${item.link}?lat=${queryParams.lat}&long=${queryParams.long}`;
// 		}
// 		window.open(link);
// 	};
//
// 	const [open, setOpen] = useState(false);
//
// 	const handleClick = () => {
// 		setOpen(!open);
// 	};
//
// 	return (
// 		<Paper elevation={3} sx={styles.main}>
// 			<List sx={styles.logoContainer}>
// 				<ListItem sx={styles.listItem}>
// 					<ListItemIcon sx={styles.listItemIcon}>
// 						<img src={AppIconImg} alt='appIcon' width='60px' />
// 					</ListItemIcon>
// 				</ListItem>
// 			</List>
// 			<Divider />
// 			<List sx={styles.list}>
// 				{leftNavItems.map((item, key) => (
// 					<StyledTooltip
// 						key={key}
// 						title={item.name}
// 						placement={'right'}
// 						TransitionComponent={Zoom}
// 						enterDelay={200}
// 					>
// 						<ListItem button sx={styles.listItem} onClick={e => handleClickListItem(item)}>
// 							<ListItemIcon sx={styles.listItemIcon}>{item.icon}</ListItemIcon>
// 						</ListItem>
// 					</StyledTooltip>
// 				))}
// 				<ListItem button sx={styles.listItem} onClick={handleClick}>
// 					<ListItemIcon sx={styles.listItemIcon}>
// 						<InfoOutlinedIcon fontSize="large" sx={{color: $primary}}/>
// 					</ListItemIcon>
// 				</ListItem>
// 				<Collapse in={open} timeout="auto" unmountOnExit>
// 					<List component="div" disablePadding>
// 						<ListItemButton sx={{ pl: 4 }}>
// 							<ListItemIcon>
// 								<img src={AloftIconImg} alt='aloftIcon' width='60px' />
// 							</ListItemIcon>
// 							<ListItemText primary="About Aloft" />
// 						</ListItemButton>
// 					</List>
// 				</Collapse>
// 			</List>
//
// 		</Paper>
// 	);
// };
//
// export default SideMenu;

import {
	List,
	ListItem,
	ListItemIcon,
	Divider,
	Zoom,
	Paper,
	Popover,
	ListItemText
} from '@mui/material';
import { leftNavItems, leftNavLinks } from 'constants/navigationConstants';
import { $backgroundOffWhite, $primary } from 'styles/colors';
import StyledTooltip from 'components/navigation/StyledTooltip';
import AppIconImg from 'assets/images/appLogo.png';
import { handleMenuItemClickEvent } from 'actions/firebase';
import queryString from 'query-string';
import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = {
	main: {
		width: '75px',
		zIndex: 200,
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		left: '20px',
		top: '20px',
		backgroundColor: $backgroundOffWhite,
		height: 'fit-content',
		padding: '0px 10px',
		borderRadius: '35px'
	},
	logoContainer: {
		height: '100px',
		display: 'flex',
		paddingBottom: '20px'
	},
	list: {
		paddingTop: '20px',
		paddingBottom: '20px'
	},
	listItem: {
		paddingTop: '10px',
		paddingBottom: '10px',
		display: 'flex',
		justifyContent: 'center',
		height: '61px',
		color: $primary
	},
	listItemIcon: {
		display: 'flex',
		justifyContent: 'center'
	}
};

const SideMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClickListItem = item => {
		handleMenuItemClickEvent(item.name);
		let link = item.link;
		if (item.name === 'Submit Data') {
			const queryParams = queryString.parse(window.location.search);
			link = `${item.link}?lat=${queryParams.lat}&long=${queryParams.long}`;
		}
		window.open(link);
	};

	const handleInfoClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'info-popover' : undefined;

	return (
		<Paper elevation={3} sx={styles.main}>
			<List sx={styles.logoContainer}>
				<ListItem sx={styles.listItem}>
					<ListItemIcon sx={styles.listItemIcon}>
						<img src={AppIconImg} alt='appIcon' width='60px' />
					</ListItemIcon>
				</ListItem>
			</List>
			<Divider />
			<List sx={styles.list}>
				{leftNavItems.map((item, key) => (
					<StyledTooltip
						key={key}
						title={item.name}
						placement={'right'}
						TransitionComponent={Zoom}
						enterDelay={200}
					>
						<ListItem button sx={styles.listItem} onClick={e => handleClickListItem(item)}>
							<ListItemIcon sx={styles.listItemIcon}>{item.icon}</ListItemIcon>
						</ListItem>
					</StyledTooltip>
				))}
				<ListItem button sx={styles.listItem} onClick={handleInfoClick}>
					<ListItemIcon sx={styles.listItemIcon}>
						<InfoOutlinedIcon fontSize='large' sx={{ color: $primary }} />
					</ListItemIcon>
				</ListItem>

				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					<List component='div' disablePadding>
						{leftNavLinks.map((item, key) => (
							<ListItem
								key={key}
								button
								sx={styles.listItem}
								onClick={e => handleClickListItem(item)}
							>
								<ListItemText primary={item.name} />
							</ListItem>
						))}
					</List>
				</Popover>
			</List>
		</Paper>
	);
};

export default SideMenu;
